import React, { Fragment, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { constants } from '../utils/constants';
import ChangeLanguage from './ChangeLanguage';
import { localeFunction } from '../locales/functions';

class MbSidebarMenu extends PureComponent {
   state = {
      isOpen: false,
   }

   componentWillUnmount() {
      if (this.props.isMobileView) {
         window.removeEventListener('click', this.addBackDrop);
      }
   }
   componentDidMount() {
      if (this.props.isMobileView) {
         window.addEventListener('click', this.addBackDrop);
      }
   }

   setMenuRef = (r) => {
      this.menuRef = r;
   }
   setToggleRef = (r) => {
      this.toggleRef = r;
   }

   addBackDrop = (e) => {
      if (this.menuRef && this.toggleRef) {
         if (this.state.isOpen && !ReactDOM.findDOMNode(this.menuRef).contains(e.target)
            && !ReactDOM.findDOMNode(this.toggleRef).contains(e.target)) {
            this.closeMenu();
         }
      }
   }

   toggleMenu = () => {
      this.setState({ isOpen: !this.state.isOpen });
   }
   closeMenu = () => {
      this.setState({ isOpen: false });
   }

   render() {
      const { isMobileView, router } = this.props;
      if (isMobileView) {
         return (
            <Fragment>
               <div className={`toggler-menu-container ${this.state.isOpen ? 'active' : ''}`}
                  ref={this.setToggleRef}
                  onClick={this.toggleMenu}>
                  <div className="toggler-menu"><span></span><span></span></div>
               </div>
               <div className={`left-menu-container ${this.state.isOpen ? 'open' : ''}`} ref={this.setMenuRef}>
                  <div className="overflow-list">
                     <Link href={'/'}>
                        <a className={`menu-item ${router.route === '/' ? 'active' : ''}`} onClick={this.closeMenu}>
                           <span>{localeFunction.locale.home}</span>
                        </a>
                     </Link>
                     {
                        constants.menuList.map(menu => (
                           <Link href={menu.route} key={menu.route}>
                              <a className={`menu-item ${router.route === menu.route ? 'active' : ''}`}
                                 onClick={this.closeMenu}
                              >
                                 <span>{menu.label}</span>
                              </a>
                           </Link>
                        ))
                     }
                  </div>
                  <div className="bottom-bar">
                     <ChangeLanguage />
                  </div>
               </div>
               <div className="blur-backdrop"></div>
               <div className={`backdrop-menu ${this.state.isOpen ? 'active' : ''}`}></div>
            </Fragment>
         )
      }
      return null;
   }
}

export default withRouter(MbSidebarMenu);