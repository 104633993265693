import React, { PureComponent } from 'react';
import IconFacebook from '../assets/icons/IconFacebook';
import IconInstagram from '../assets/icons/IconInstagram';
import { localeFunction } from '../locales/functions';

class Footer extends PureComponent {
	render() {
		return (
			<div className="main-footer">
				<div className="socials">
					<a href="https://www.facebook.com/dnaparchitects" target="_blank">
						<IconFacebook />
					</a>
					<a href="https://www.instagram.com/dnaparchitects/" target="_blank">
						<IconInstagram />
					</a>
				</div>
				<span>
					@ 2021-{new Date().getFullYear()} {localeFunction.locale.copyright}
				</span>
			</div>
		);
	}
}

export default Footer;
