import React, { PureComponent } from 'react';
import { withRouter } from 'next/router';
import Link from 'next/link';
import { constants } from '../utils/constants';

class BottomMenu extends PureComponent {
   render() {
      let { route } = this.props.router;
      return (
         <div className="category-list-container bottom-menu">
            <div className="categories-list">
               {
                  constants.menuList.map(menu => (
                     <Link href={menu.route} key={menu.route}>
                        <a className={`category-menu ${(route === menu.route || this.props.activeAll) ? 'active' : 'deactive'}`}>
                           {menu.label}
                        </a>
                     </Link>
                  ))
               }
            </div>
         </div>
      );
   }
}

export default withRouter(BottomMenu);