import React, { PureComponent } from 'react';

class IconNext extends PureComponent {
   render() {
      return (
         <svg
            width={17}
            height={17}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M8.05138 1.50073L15.5514 9.00073L8.05138 16.5007M15.7339 9.03251L2.26611 9.03251"
               stroke="currentColor"
               strokeWidth="1.5"
            />
         </svg>
      );
   }
}

export default IconNext;