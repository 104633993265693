import { serviceCommon } from "./common";
import { apiConstants } from "./constants";

const getCategoryList = async (abortController) => {
   let restObj = {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
      },
   }
   if (abortController) {
      restObj.signal = abortController.signal;
   }
   let url = apiConstants.GET_CATEGORIES;

   try {
      let response = await fetch(url, restObj);
      let body = await response.json();
      return body;
   }
   catch (e) {
      return [];
   }
}

export const categoryApi = {
   getCategoryList,
}