import Head from 'next/head';

const Layout = ({ title, WrappedComponent, ...props }) => {
   return (
      <div className={`app ${props.isMobileView ? 'mobile-view' : ''}`}>
         <Head>
            <title>{title || 'DNAP ARCHITECTURE'}</title>
            <link rel="icon" href="/favicon.ico" />
            <link href="/globals.css" rel="stylesheet" />
            <link
               rel="stylesheet"
               href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
            />
            <style>
               {`
						@font-face {
							font-family: 'Myriadpro';
							src: url('/fonts/MYRIADPRO/MYRIADPRO-LIGHT.OTF');
						}
						@font-face {
							font-family: 'Myriadpro-semic';
							src: url('/fonts/MYRIADPRO/MYRIADPRO-LIGHTSEMICN.OTF');
						}
					`}
            </style>
         </Head>

         <main className="main">
            <WrappedComponent {...props} />
         </main>
      </div>
   )
}

export default Layout;
