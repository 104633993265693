import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import Link from 'next/link';
import { constants } from '../utils/constants';
import { localeFunction } from '../locales/functions';

class CategoryList extends PureComponent {
	state = {
		dropdownOpen: false,
	};

	setMenuRef = (r) => {
		this.menuRef = r;
	};

	addBackDrop = (e) => {
		if (this.menuRef) {
			if (this.state.dropdownOpen && !ReactDOM.findDOMNode(this.menuRef).contains(e.target)) {
				this.closeDropdown();
			}
		}
	};

	componentWillUnmount() {
		if (this.props.isMobileView) {
			window.removeEventListener('click', this.addBackDrop);
		}
	}

	componentDidMount() {
		if (this.props.isMobileView) {
			window.addEventListener('click', this.addBackDrop);
		}
	}

	closeDropdown = () => {
		this.setState({
			dropdownOpen: false,
		});
	};

	toggleDropdown = (e) => {
		e.stopPropagation();
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		});
	};

	renderCategoriesMobile = (categories, currentCat) => {
		let current = categories.find((c) => c.slug === currentCat);
		const currentLocale = localeFunction.getCurrentLocale();

		return (
			<div className="categories-select-container">
				<div className="current-box" onClick={this.toggleDropdown}>
					<span>{current ? current[`name_${currentLocale}`] : 'All'}</span>
					<svg
						width={24}
						height={24}
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M17 10L12 15L7 10"
							stroke="currentColor"
							strokeMiterlimit={10}
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div
					className={`categories-dropdown ${
						this.state.dropdownOpen ? 'open animate__animated animate__fadeIn' : ''
					}`}
					ref={this.setMenuRef}
				>
					{categories.map((cat) => {
						return (
							<Link href={`/?${constants.CATEGORY_QUERY}=${cat.slug}`} key={cat.slug}>
								<a
									className={`category-menu ${
										currentCat ? (currentCat === cat.slug ? 'active' : 'deactive') : ''
									} ${this.props.deActive ? 'deactive' : ''}`}
									onClick={this.closeDropdown}
								>
									{cat[`name_${currentLocale}`]}
								</a>
							</Link>
						);
					})}
				</div>
			</div>
		);
	};

	render() {
		const { categories = [], currentCat, deActive, isMobileView } = this.props;
		const currentLocale = localeFunction.getCurrentLocale();

		return (
			<div className="category-list-container">
				<div className="categories-list">
					{!isMobileView
						? categories.map((cat) => {
								return (
									<Link href={`/?${constants.CATEGORY_QUERY}=${cat.slug}`} key={cat.slug}>
										<a
											className={`category-menu ${
												currentCat
													? currentCat === cat.slug
														? 'active'
														: 'deactive'
													: ''
											} ${this.props.deActive ? 'deactive' : ''}`}
										>
											{cat[`name_${currentLocale}`]}
										</a>
									</Link>
								);
						  })
						: this.renderCategoriesMobile(categories, currentCat)}
				</div>
			</div>
		);
	}
}

export default CategoryList;
