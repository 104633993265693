import React, { PureComponent } from 'react';

class IconInstagram extends PureComponent {
   render() {
      return (
         <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M18.2537 6.57135C18.2443 5.87141 18.1133 5.17844 17.8664 4.52341C17.6523 3.97091 17.3254 3.46913 16.9064 3.05015C16.4874 2.63116 15.9856 2.30418 15.4331 2.0901C14.7865 1.84738 14.1034 1.71613 13.4129 1.70195C12.5239 1.66221 12.242 1.65112 9.98519 1.65112C7.72839 1.65112 7.43913 1.65112 6.55656 1.70195C5.86636 1.71623 5.18358 1.84748 4.53727 2.0901C3.98467 2.30403 3.48282 2.63096 3.06382 3.04996C2.64481 3.46897 2.31789 3.97082 2.10395 4.52341C1.86074 5.16952 1.72978 5.85246 1.71673 6.5427C1.67699 7.43267 1.66498 7.71454 1.66498 9.97134C1.66498 12.2281 1.66498 12.5165 1.71673 13.4C1.73059 14.0912 1.8609 14.7733 2.10395 15.4211C2.31825 15.9735 2.64542 16.4752 3.06456 16.894C3.4837 17.3128 3.9856 17.6396 4.53819 17.8535C5.18273 18.106 5.86563 18.2466 6.55748 18.2694C7.44745 18.3091 7.72932 18.3211 9.98612 18.3211C12.2429 18.3211 12.5322 18.3211 13.4148 18.2694C14.1052 18.2558 14.7884 18.1248 15.435 17.8821C15.9873 17.6678 16.489 17.3408 16.9079 16.9218C17.3269 16.5028 17.654 16.0012 17.8683 15.4488C18.1113 14.8019 18.2416 14.1199 18.2555 13.4277C18.2952 12.5387 18.3073 12.2568 18.3073 9.99906C18.3054 7.74226 18.3054 7.45577 18.2537 6.57135ZM9.97965 14.2391C7.61934 14.2391 5.70725 12.327 5.70725 9.96672C5.70725 7.60641 7.61934 5.69432 9.97965 5.69432C11.1128 5.69432 12.1995 6.14445 13.0007 6.94568C13.8019 7.74691 14.252 8.83361 14.252 9.96672C14.252 11.0998 13.8019 12.1865 13.0007 12.9878C12.1995 13.789 11.1128 14.2391 9.97965 14.2391ZM14.4221 6.53254C13.8704 6.53254 13.4258 6.08709 13.4258 5.53629C13.4258 5.40552 13.4516 5.27604 13.5016 5.15522C13.5517 5.03441 13.625 4.92464 13.7175 4.83217C13.81 4.7397 13.9197 4.66635 14.0406 4.61631C14.1614 4.56627 14.2909 4.54051 14.4216 4.54051C14.5524 4.54051 14.6819 4.56627 14.8027 4.61631C14.9235 4.66635 15.0333 4.7397 15.1258 4.83217C15.2182 4.92464 15.2916 5.03441 15.3416 5.15522C15.3917 5.27604 15.4174 5.40552 15.4174 5.53629C15.4174 6.08709 14.972 6.53254 14.4221 6.53254Z"
               fill="currentColor"
            />
            <path
               d="M9.97965 12.742C11.5124 12.742 12.7549 11.4995 12.7549 9.96674C12.7549 8.434 11.5124 7.19148 9.97965 7.19148C8.44692 7.19148 7.20439 8.434 7.20439 9.96674C7.20439 11.4995 8.44692 12.742 9.97965 12.742Z"
               fill="currentColor"
            />
         </svg>
      );
   }
}

export default IconInstagram;